import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { navigate } from 'gatsby';
import {
  Typography,
  Box,
  Container,
  Section,
  Button,
  Notice,
  RawHTML,
  Grid,
  Layout,
  Loader,
  PreLoader,
  Toast,
  Dialog,
  UpdateCandidateForm,
} from '../../components';
import { JobIcon, TimeIcon } from '../../icons';
import dotBg from '../../images/dot-bg.png';
import dotBgMobile from '../../images/dot-bg-mobile.png';
import media from '../../styles/mediaQuery';
import {
  useGetJob,
  useUploadResume,
  useCandidateAssociate,
  useUpdateCandidate,
} from '../../hooks/useFetch';
import { validatePDFFile } from '../../utils/validator';
import { emailAddress } from '../../config';

const HeaderBg = styled(Box)`
  background-position-x: right;
  background-image: url(${dotBgMobile});

  ${media.sm`
    background-image: url(${dotBg});
    background-size: 80%;
    background-repeat: no-repeat;
    background-position-x: right;
  `}
`;

const FileInput = styled.div`
  label {
    background-color: #f66d1d;
    color: #ffffff;
    padding: 16px 24px;
    cursor: pointer;
  }
`;

export default function JobByID({ params }) {
  const { id } = params;
  const {
    isLoading,
    data,
    isError: isGettingJobError,
    error: jobErrorData,
  } = useGetJob(id);
  const {
    isLoading: isUploadingResume,
    mutate: uploadResume,
    isError: isUploadResumeError,
    reset: resetUploadResumeError,
    data: resumeData,
  } = useUploadResume();
  const {
    isLoading: isUpdatingCandidate,
    mutate: updateCandidate,
    isError: isuUpdateCandidateError,
    reset: resetUpdateCandidateError,
  } = useUpdateCandidate();
  const associateCandidate = useCandidateAssociate();
  const job = data?.data ?? {};
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileUpload = async event => {
    const formData = new FormData();
    const file = event.target.files[0];
    const hasError = validatePDFFile(file);
    if (hasError) {
      setErrorMessage(hasError);
      return;
    }
    setErrorMessage('');
    formData.append('document', file);
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
    uploadResume(
      { data: formData },
      {
        onSuccess: responseData => {
          const candidateData = responseData?.data?.data;
          const associateData = { id: candidateData?.id, jobID: id };
          associateCandidate.mutate({ data: associateData });
          setOpen(false);
          setInfoOpen(true);
        },
      }
    );
  };

  useEffect(() => {
    if (isUploadResumeError) {
      resetUploadResumeError();
    }
    if (isuUpdateCandidateError) {
      resetUpdateCandidateError();
    }
  }, [open]);

  const handleSubmitData = async values => {
    const updateData = { id: resumeData?.data?.data?.id, ...values };

    updateCandidate(
      { data: updateData },
      {
        onSuccess: () => {
          setInfoOpen(false);
          setOpenToast(true);
        },
      }
    );
  };

  if (isGettingJobError) {
    if (jobErrorData?.response?.status === 404) {
      navigate('/404/');
      <PreLoader loading minHeight="60vh" />;
    } else {
      return (
        <Layout>
          <Typography
            mt={10}
            textAlign="center"
            variant="heading1"
            color="error"
          >
            Sorry! We have encountered an error!
          </Typography>
        </Layout>
      );
    }
  }

  return (
    <Layout
      title={job.title}
      description="Inspire and enable businesses to accelerate and bring ideas to life through technology and software development."
      image="/images/default-job.jpg"
    >
      <Container mt={{ _: 8, md: 10 }}>
        <PreLoader loading={isLoading} minHeight="60vh">
          <HeaderBg position="relative" py={5} pl={{ _: 2, md: 0 }}>
            <Typography variant="heading1">{job?.title}</Typography>
            <Button
              onClick={() => setOpen(true)}
              color="primary"
              variant="contained"
              ml="20%"
              mt={2}
            >
              Apply Now
            </Button>
          </HeaderBg>
          <Section mt={{ _: 3, md: 6 }}>
            <Box bg="#071526" py={{ _: 4, md: 2 }} px={{ _: 1, md: 0 }}>
              <Grid
                gridGap={{ _: '2', md: '4' }}
                gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr 1fr 1fr' }}
                justifyItems="center"
                alignItems="center"
              >
                <Grid item xs={12} md={3}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ _: 'flex-start', md: 'center' }}
                    mb={{ _: 2, md: 0 }}
                  >
                    <JobIcon style={{ fontSize: 15, marginTop: '-4px' }} />
                    <Typography ml={1} variant="paragraph2">
                      Number of Posts : {job?.numberOfPosition}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ _: 'flex-start', md: 'center' }}
                    mb={{ _: 2, md: 0 }}
                  >
                    <TimeIcon />
                    {job?.publishedDate && (
                      <Typography ml={1} variant="paragraph2">
                        Posted On :
                        {format(new Date(job?.publishedDate), 'MMMM dd, yyyy')}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ _: 'flex-start', md: 'center' }}
                    mb={{ _: 2, md: 0 }}
                  >
                    <TimeIcon />
                    {job?.deadLine && (
                      <Typography ml={1} variant="paragraph2">
                        Deadline :
                        {format(new Date(job?.deadLine), 'MMMM dd, yyyy')}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography
                    textAlign={{ _: 'left', md: 'center' }}
                    variant="paragraph2"
                  >
                    {job?.jobType}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Section>
          <Section mt={{ _: 3, md: 6 }}>
            <RawHTML html={job?.description} />
          </Section>

          {/* <Section mt={{ _: 3, md: 6 }}>
            <Notice img="career/notice-image-01.jpg">
              <Typography variant="heading5" mb={2}>
                Referral Bonus:
              </Typography>
              <Typography variant="paragraph2">
                In case this opportunity may not be of your interest, we would
                be sincerely grateful if you would pass this on to anyone you
                think might be interested. ATM, we are paying flat BDT 10,000
                for a successful referral.
              </Typography>
            </Notice>
          </Section> */}

          {job?.salary && (
            <Section mt={{ _: 5, md: 8 }}>
              <Typography variant="heading3">Salary: {job.salary}</Typography>
            </Section>
          )}

          <Section>
            <Button
              onClick={() => setOpen(true)}
              color="primary"
              variant="contained"
            >
              Apply Now
            </Button>
          </Section>
        </PreLoader>
      </Container>

      <Dialog open={open} handleClose={() => setOpen(false)}>
        <Loader loading={isUploadingResume} opacity="0.3">
          <HeaderBg position="relative" py={5} pl={{ _: 2, md: 0 }}>
            <Typography variant="heading1">{job?.title}</Typography>
          </HeaderBg>

          <Box mt="60px" display="flex">
            <FileInput>
              <input
                type="file"
                id="upload"
                hidden
                onChange={handleFileUpload}
              />
              <label htmlFor="upload">Browse your CV</label>
            </FileInput>

            <Typography ml={3} variant="paragraph2">
              (PDF Only)
            </Typography>
          </Box>

          {isUploadResumeError && (
            <Typography color="error" mt={4}>
              We are facing an error, please try again later or send your CV to
              this email address:{' '}
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </Typography>
          )}
          {errorMessage && (
            <Typography color="error" mt={4}>
              {errorMessage}
            </Typography>
          )}
        </Loader>
      </Dialog>

      <Dialog open={infoOpen} handleClose={() => setInfoOpen(false)}>
        <HeaderBg position="relative" py={5} pl={{ _: 2, md: 0 }}>
          <Typography variant="heading1">{job?.title}</Typography>
        </HeaderBg>
        <Loader loading={isUpdatingCandidate} opacity="0.3">
          <UpdateCandidateForm
            candidateData={resumeData?.data?.data || {}}
            handleSubmitData={handleSubmitData}
          />
          {isuUpdateCandidateError && (
            <Typography color="error" mt={4}>
              We are facing an error, please try again later or send your CV to
              this email address:{' '}
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </Typography>
          )}
        </Loader>
      </Dialog>
      <Toast open={openToast} setOpen={setOpenToast} />
    </Layout>
  );
}
